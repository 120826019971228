
import React from "react"

import CustomereditorPage from "../../components/negosyonow/controls/customereditor";


const BuyerPage = ({location}) => {
	return <CustomereditorPage
				location={location}
				allowadd={false}
			/>
}


export default BuyerPage;
