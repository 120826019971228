
import React, {useState} from "react"
import Layout from "../../layout"

import WebappsForm from "../../webapps/form"

const webappsAPI = require("../../../../lib/requestWebapps");

const ENTITYID="onsperson"

const onscontactpickerfields = [
	{"label":"Details", "dbfield": "onscontact_info", "type": "text", "filtertype": "textbox"},
	{"label":"Type", "dbfield": "onscontacttype_name", "type": "text", "filtertype": "textbox"},
];


const onsaddresspickerfields = [
	{"label":"Full Address", "dbfield": "onsaddress_name", "type": "text", "filtertype": "textbox"},
	{"label":"City", "dbfield": "onsaddress_city", "type": "text", "filtertype": "textbox"},
	{"label":"Province", "dbfield": "onsaddress_province", "type": "text", "filtertype": "textbox"},
];

const onsorganizationpickerfields = [
	{"label":"Name", "dbfield": "onsorganization_name", "type": "text", "filtertype": "textbox"},
	{"label":"Negosyo Now Seller", "dbfield": "onsorganization_branchid", "type": "checkbox", "filtertype": "none"},
];

/*
const onsorganizationpickerfilter = [
	// Active
	{"field":"onsorganization_name", "operation": "<>", "value": 0}
];
*/


/*
	"onsperson_name"=>$tmpname,
	"onsperson_shortname"=>$tmpnickname,
	"onsperson_lastname"=>trim($_REQUEST['namelastname']),
	"onsperson_firstname"=>trim($_REQUEST['namefirstname']),
	"onsperson_middlename"=>trim($_REQUEST['namemiddlename']),
	"onsperson_suffixname"=>trim($_REQUEST['namesuffixname']),
	"onsperson_msgname"=>$tmpformalname,
	"onsperson_bday"=>$_REQUEST['namebday'],

*/

const codetypelist = [
	{"value":0,"display":"Unknown/None"},
	{"value":1,"display":"Password"},
	{"value":2,"display":"Guest"},

];

const searchFields = [
	{"label":"Name", "dbfield": "onsperson_name", "type": "text", "filtertype": "textbox"},
	{"label":"Formal Name", "dbfield": "onsperson_msgname", "type": "text", "filtertype": "textbox"},
	{"label":"Contacts", "dbfield": "onsperson_contactsummary", "type": "text", "filtertype": "textbox"},
	{"label":"Login Type", "dbfield": "onsperson_codetype", "type": "combo", "filtertype": "combo", "options": codetypelist},
];

const formFields = [
	[
		{"label":"Formal Name", "field": "onsperson_msgname", "value": "", "input": "textbox", "mode": "normal", "info":"Used when sending messages"},
		{"label":"First Name", "field": "onsperson_firstname", "value": "", "input": "textbox", "mode": "required"},
		{"label":"Middle Name", "field": "onsperson_middlename", "value": "", "input": "textbox", "mode": "normal"},
		{"label":"Last Name", "field": "onsperson_lastname", "value": "", "input": "textbox", "mode": "normal"},
		{"label":"Suffix", "field": "onsperson_suffixname", "value": "", "input": "textbox", "mode": "normal", "info":"Jr., III, etc."},
		{"label":"Nick Name/Alias", "field": "onsperson_shortname", "value": "", "input": "textbox", "mode": "normal"},
		//{"label":"Age", "field": "onsperson_bday", "value": "", "input": "textbox", "mode": "readonly"},
		{"label":"Login Type", "field": "onsperson_codetype", "value": "", "input": "combo", "options": codetypelist, "mode": "readonly"},
	]
];


const subformFields = [
	{
		"subformid": "onspersondetail",
		"label": "Additional Information",
		"table": "onspersondetail",
		"sort": "onspersondetail_label",
		"mobilerowtitlefieldidx":[1],
		"minrow": 0,
		"maxrow": 100,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "Type",
				"field": "onspersondetail_label",
				"value": "",
				"input": "textbox",
				"mode": "required"
			},
			{
				"label": "Detail",
				"field": "onspersondetail_info",
				"value": "",
				"input": "textbox",
				"mode": "required"
			}
		],
		"footerlist": [
		]
	},
	{
		"subformid": "onspersonorganization",
		"label": "Organizations",
		"table": "onspersonorganization",
		"sort": "onspersonorganization_start desc",
		"mobilerowtitlefieldidx":[1],
		"minrow": 0,
		"maxrow": 100,
		"allowdel": false,
		"fieldlist": [
			{
				"label": "Organization",
				"field": "onsorganization_name",
				"value": "",
				"input": "picker",
				"pickerfields": onsorganizationpickerfields,
				"pickercreateurl": "/listmanager/organization",
				"mode": "required"
			},
			{
				"label": "Role",
				"field": "onspersonorganization_role",
				"value": "",
				"input": "textbox",
				"mode": "normal"
			},
			{
				"label": "Active",
				"field": "onspersonorganization_active",
				"value": "",
				"input": "checkbox",
				"mode": "normal"
			},
			{
				"label": "Date Started",
				"field": "onspersonorganization_start",
				"value": "",
				"input": "date",
				"mode": "normal"
			},
			{
				"label": "Date Ended",
				"field": "onspersonorganization_end",
				"value": "",
				"input": "date",
				"mode": "normal"
			},
			{
				"label": "Notes",
				"field": "onspersonorganization_notes",
				"value": "",
				"input": "textbox",
				"mode": "normal"
			}

		],
		"footerlist": [
		]
	},
	{
		"subformid": "onspersoncontact",
		"label": "Contact",
		"table": "onspersoncontact",
		"sort": "onspersoncontact_primary desc",
		"mobilerowtitlefieldidx":[0],
		"minrow": 0,
		"maxrow": 100,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "Contact",
				"field": "onscontact_info",
				"value": "",
				"input": "picker",
				"pickerfields": onscontactpickerfields,
				"pickercreateurl": "/listmanager/contactinfo",
				"mode": "required"
			},
			{
				"label": "Primary",
				"field": "onspersoncontact_primary",
				"value": "",
				"input": "checkbox",
				"mode": "normal"
			},
			{
				"label": "Notes",
				"field": "onspersoncontact_notes",
				"value": "",
				"input": "textbox",
				"mode": "normal"
			}

		],
		"footerlist": [
		]
	},
	{
		"subformid": "onspersonaddress",
		"label": "Addresses",
		"table": "onspersonaddress",
		"sort": "onspersoncontact_primary desc",
		"mobilerowtitlefieldidx":[0],
		"minrow": 0,
		"maxrow": 100,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "Address",
				"field": "onsaddress_name",
				"value": "",
				"input": "picker",
				"pickerfields": onsaddresspickerfields,
				"pickercreateurl": "/listmanager/address",
				"mode": "required"
			},
			{
				"label": "Primary",
				"field": "onspersonaddress_primary",
				"value": "",
				"input": "checkbox",
				"mode": "normal"
			},
			{
				"label": "Notes",
				"field": "onspersonaddress_notes",
				"value": "",
				"input": "textbox",
				"mode": "normal"
			}

		],
		"footerlist": [
		]
	},
];

const CustomereditorPage = ({location, allowadd}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");

	const [defaultdata, setDefaultdata] = useState({});

	function customSubmit(entity, params, payloadlist, subformpayloadlist, token, callback)
	{
		var pkid = 0;
		//var customparam = {};
		var customparam = JSON.parse(JSON.stringify(params));
		var defaultname = "";
		var tmpstr = "";
		var nameneedsupdate = false;

		if (params.hasOwnProperty("documentid")) {
			pkid = params.documentid;
		}
		defaultname = getFormValue(params, "onsperson_firstname");
		tmpstr = getFormValue(params, "onsperson_lastname");
		if (tmpstr.length > 0) {
			defaultname = defaultname + " " + tmpstr;
		}

		if (null === defaultdata.onsperson_msgname || "" === defaultdata.onsperson_msgname ) {
			if (!params.hasOwnProperty("onsperson_msgname")) {
				customparam.onsperson_msgname = defaultname;
			}
		}
		if (params.hasOwnProperty("onsperson_firstname") || params.hasOwnProperty("onsperson_lastname")) {
			nameneedsupdate = true;
		}
		if (nameneedsupdate) {
			customparam.onsperson_name = defaultname;
		}

		//console.log(customparam);
		//callback({"status":"Error"}); return;

		webappsAPI.savePayload(entity, customparam, token, payloadlist, subformpayloadlist, callback);
	}

	function getFormValue(params, fieldname)
	{
		if (params.hasOwnProperty(fieldname)) {
			return params.onsperson_firstname;
		} else if (defaultdata[fieldname]) {
			return defaultdata[fieldname];
		}
		return ""
	}


	function customSetFormData(newformdata)
	{
		setDefaultdata(newformdata);
	}

	return <Layout location={location}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			privatePage={true} usegatedcontent={true}>
			<WebappsForm
				location={location}
				allowadd={typeof allowadd !== "undefined"?allowadd:false}
				allowdelete={false}
				pagetitle={pagetitle}
				mobilerowtitlefield={["onsperson_name"]}
				searchFields={searchFields}
				editFields={formFields}
				editSubFormFields={subformFields}
				userSearchFilter={[{"field":"onsperson_codetype", "operation":">", "value": 0}]}

				customSubmit={customSubmit}
				customSetFormData={customSetFormData}

				entity={ENTITYID}
				token={token} />
		</Layout>
}


export default CustomereditorPage;
